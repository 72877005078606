import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { alertController, modalController } from "@ionic/core";
import { ObservableSection, type ObservableGroup } from "../stores/groups.store";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { ClientResponseError } from "pocketbase";
import { CreatePatientSchema, type CreatePatient } from "../repository/patient/patient";
import { patientsStore } from "../stores/patients.store";
import { Page, required } from "../components/component";
import { format, formatISO } from "date-fns";
import { pt } from "date-fns/locale";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalSheetNav } from "./modal-sheet-nav";
import type { IonActionSheet } from "@ionic/core/components/ion-action-sheet";
import { repeat } from "lit/directives/repeat.js";
import { FilterStore } from "../stores/filter.store";
import { ModalChoosePatientCategory } from "./modal-choose-patient-category";
import type { ModalNav } from "./modal-nav";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

@customElement("modal-create-patient")
@localized()
export class ModalNewPatient extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  @state() private _selectedGender: string = "";
  @state() private _hasAvatar: boolean = false;
  @state() private _birthdate: string = formatISO(new Date());

  #modalDatePickerRef: Ref<ModalSheetNav> = createRef();
  #modalCropPhotoRef: Ref<ModalNav> = createRef();
  #sheetGenderRef: Ref<IonActionSheet> = createRef();

  filterStore = new FilterStore();

  @required() group!: ObservableGroup;
  @required() section!: ObservableSection;

  connectedCallback() {
    super.connectedCallback();

    // set the group and section id in the form
    this.#controller.form.change("group", this.group.id);
    this.#controller.form.change("section", this.section.id);
  }

  #controller = new FinalFormController<CreatePatient>(this, {
    validate: zodValidator(CreatePatientSchema()),
    onSubmit: async (values) => {
      try {
        const createPatient: CreatePatient = {
          ...values,
          birthdate: formatISO(this._birthdate),
          categories: this.filterStore.filtersByKey("categories").map((category) => category.id),
        };

        await patientsStore.createPatient(this.section, createPatient);

        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: [msg("OK")],
          });
          alert.present();
        }
      }
    },
  });

  async firstUpdated(_changedProperties: Map<string, any>) {
    super.firstUpdated(_changedProperties);

    const fileInput = this.renderRoot.querySelector("#avatar-file") as HTMLInputElement;
    fileInput.addEventListener("change", (event) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
        const file = input.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target?.result as string;
          this.#modalCropPhotoRef.value?.open({
            image: imageUrl,
            updateImage: this.updateCroppedImage,
            onCancel: this.clearAvatarForm,
          });
        };
        reader.readAsDataURL(file);
        input.value = "";
      }
    });
  }

  updateCroppedImage = (canvas: HTMLCanvasElement) => {
    const image = canvas.toDataURL("image/jpeg");
    const button = document.querySelector("#avatar-button") as HTMLButtonElement;
    button.style.backgroundImage = `url(${image})`;
    button.style.backgroundSize = "cover";
    this._hasAvatar = true;
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const fileName = `cropped-image-${Date.now()}.jpeg`;
          const file = new File([blob], fileName, { type: "image/jpeg" });
          this.#controller.form.change("avatar", file);
        } else {
          console.error("Canvas to Blob conversion failed");
        }
      },
      "image/jpeg",
      0.8,
    );
  };

  clearAvatarForm = () => {
    this.#controller.form.change("avatar", undefined);
  };

  changeGenderInputValue(value: string, formValue: string) {
    this._selectedGender = value;
    this.#controller.form.change("gender", formValue);
    this.#sheetGenderRef.value?.dismiss();
  }

  getDay(date: string) {
    if (!date) return;
    const time = format(date, "dd-MM-yyyy", { locale: pt });
    return time;
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    if (this._birthdate) {
      form.change("birthdate", this.getDay(this._birthdate));
    }

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m"
                @click="${() => modalController.dismiss()}">
                ${msg("Cancelar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Novo Utente")}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="space-y-4" fullscreen>
        <!-- HEADER -->
        <div class="h-2"></div>
        <form
          id="form-patient-create"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}
          class="col space-y-4 pb-4">
          <div class="mx-auto">
            <button
              id="avatar-button"
              type="button"
              class="w-36 h-36 rounded-full bg-accent-8 border border-solid border-accent-7"
              aria-label="avatar"
              @click=${() => {
                const avatarFile = this.renderRoot.querySelector("#avatar-file") as HTMLInputElement;
                avatarFile.click();
              }}>
              ${when(
                !this._hasAvatar,
                () => html`
                  <div slot="icon-only">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path
                        d="M8.5 12H12.5M12.5 12H16.5M12.5 12V16M12.5 12V8M12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                `,
              )}
            </button>
            ${renderError("avatar")}
            <input id="avatar-file" class="hidden" accept="image/*" type="file" ${register("avatar")} />
          </div>

          <div class="space-y-2.5">
            <span class="font-display font-semibold">${msg("Categoria")}</span>
            ${when(this.filterStore.hasFilters, () => {
              return html`
                <div class="my-1.5 flex items-center flex-wrap gap-1.5">
                  ${repeat(
                    this.filterStore.filtersByKey("categories"),
                    (category) => category.id,
                    (category) => {
                      return html` <ion-chip
                        style="--background: ${category.color}"
                        class="font-semibold font-body text-sm px-2.5 py-1.5"
                        @click=${() => this.filterStore.remove(category)}
                        >${category.name}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          class="ml-1">
                          <rect width="12" height="12" rx="6" fill="black" />
                          <path
                            d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </ion-chip>`;
                    },
                  )}
                </div>
              `;
            })}

            <ion-button
              @click=${() => {
                this.navigator.push(ModalChoosePatientCategory, {
                  filterStore: this.filterStore,
                });
              }}
              class="font-bold"
              color="secondary"
              shape="round"
              size="small"
              expand="block"
              >${msg("Adicionar")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                class="ml-2">
                <path
                  d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </ion-button>
          </div>
          <ion-list lines="none" class="space-y-4 flex flex-col">
            <div>
              <span class="font-semibold font-display">${msg("Nome")}</span>
              <ion-item class="mt-1">
                <ion-input
                  type="text"
                  placeholder=${msg("Nome do paciente")}
                  autocapitalize="words"
                  ${register("name")}>
                </ion-input>
              </ion-item>
              ${renderError("name")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Email")}</span>
              <ion-item class="mt-1">
                <ion-input type="email" placeholder="email@gmail.com" ${register("email")}> </ion-input>
              </ion-item>
              ${renderError("email")}
            </div>
            <div>
              <span class="font-semibold font-display">Telemóvel</span>
              <ion-item class="mt-1">
                <ion-input type="tel" placeholder="912345678" ${register("phone")}> </ion-input>
              </ion-item>
              ${renderError("phone")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Género")}</span>
              <ion-item class="mt-1">
                <ion-input
                  ${register("gender")}
                  readonly
                  value="${this._selectedGender}"
                  placeholder=${msg("Clique para selecionar")}
                  @click=${() => this.#sheetGenderRef.value?.present()}>
                </ion-input>
              </ion-item>
              ${renderError("gender")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Data de Nascimento")}</span>
              <ion-item class="mt-1">
                <ion-input
                  readonly
                  ${register("birthdate")}
                  placeholder="01-01-2000"
                  @click=${() =>
                    this.#modalDatePickerRef.value?.open({
                      date: formatISO(this._birthdate),
                      setDate: (date: string) => {
                        this._birthdate = date;
                      },
                    })}>
                </ion-input>
              </ion-item>
              ${renderError("birthdate")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Residência")}</span>
              <ion-item class="mt-1">
                <ion-input autocapitalize="on" ${register("address")} type="text" placeholder=${msg("Localidade")}>
                </ion-input>
              </ion-item>
              ${renderError("address")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Informação Clínica")}</span>
              <ion-item class="mt-1">
                <ion-textarea
                  ${register("clinicInfo")}
                  auto-grow
                  rows="4"
                  type="text"
                  placeholder=${msg("Digite aqui a informação necessária")}>
                </ion-textarea>
              </ion-item>
              ${renderError("clinicInfo")}
            </div>
          </ion-list>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar style="--background: transparent;">
          <ion-button
            form="form-patient-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Criar Utente")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-sheet-nav
        auto-height
        root="modal-date-picker"
        border-radius=${0}
        .handle=${false}
        ${ref(this.#modalDatePickerRef)}></modal-sheet-nav>

      <modal-nav root="modal-crop-photo" ${ref(this.#modalCropPhotoRef)}></modal-nav>

      <ion-action-sheet
        ${ref(this.#sheetGenderRef)}
        .buttons=${[
          {
            text: msg("Masculino"),
            role: "selected",
            handler: () => {
              this.changeGenderInputValue(msg("Masculino"), "male");
            },
          },
          {
            text: msg("Feminino"),
            role: "selected",
            handler: () => {
              this.changeGenderInputValue(msg("Feminino"), "female");
            },
          },
          {
            text: msg("Outro"),
            role: "selected",
            handler: () => {
              this.changeGenderInputValue(msg("Outro"), "other");
            },
          },
          {
            text: msg("Cancelar"),
            role: "cancel",
          },
        ]}></ion-action-sheet>
    `;
  }
}
