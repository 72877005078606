import type { IonInput } from "@ionic/core/components/ion-input";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { consume } from "@lit/context";
import { Task, TaskStatus } from "@lit/task";
import { html, nothing, type PropertyValues } from "lit";
import { customElement, state } from "lit/decorators.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { Page } from "../components/component";
import { authContext, type AuthContext } from "../context/auth.context";
import { RouterContext, routerContext } from "../context/router.context";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import type { ModalNav } from "../modals/modal-nav";
import { ObservableEvaluation } from "../stores/evaluation.store";
import { FilterStore } from "../stores/filter.store";
import { reportsStore } from "../stores/reports.store";
import Fuse from "fuse.js";
import { sleep } from "../pocketbase";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

@customElement("page-report")
@localized()
export class PageReport extends Page {
  @state() private _id: string = "";
  @state() private _query = "";

  #ionRefresher: Ref<IonRefresher> = createRef();
  #modalNewReportRef: Ref<ModalNav> = createRef();
  #modalFilterReports: Ref<ModalNav> = createRef();
  #modalEditReportLibraryRef: Ref<ModalNav> = createRef();

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  filterStore = new FilterStore();

  connectedCallback() {
    super.connectedCallback();
    this._id = this.router.getParam("reportId");
    this.#loadReport.run([true]).then(() => {
      this.#loadReportTemplates.run([true]);
    });
  }

  firstUpdated(props: PropertyValues) {
    super.firstUpdated(props);
    const modalNewReport = this.#modalNewReportRef.value;
    if (modalNewReport) {
      modalNewReport.onWillClose = () => {
        this.#loadReportTemplates.run([true]);
      };
    }
    const modalEditReportLibrary = this.#modalEditReportLibraryRef.value;
    if (modalEditReportLibrary) {
      modalEditReportLibrary.onWillClose = () => {
        this.#loadReportTemplates.run([true]);
      };
    }
  }

  #loadReport = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return reportsStore.loadReport(this._id, { cache });
    },
  });

  #loadReportTemplates = new Task(this, {
    task: async ([cache]: [boolean]) => {
      const report = await reportsStore.loadReportTemplates(this._id, { cache });
      if (!report) return [];
      return report;
    },
  });

  #filterReportTemplates = new Task(this, {
    task: async () => {
      await sleep(100);

      let templates = this.#loadReport.value?.allTemplates;
      if (!templates) return [];

      if (this.filterStore.hasFilters) {
        templates = templates.filter((template) => {
          const categories = this.filterStore.filtersByKey("categories");
          const type = this.filterStore.filtersByKey("type")?.[0]?.id;

          const hasCategories =
            categories.length === 0 ||
            template.categories.some((category) => categories.some((c) => c.id === category.id));

          if (type) {
            const isEvaluation = template instanceof ObservableEvaluation;
            const result = hasCategories && (type === "evaluation" ? isEvaluation : !isEvaluation);
            return result;
          }

          return hasCategories;
        });
      }

      if (this._query.length > 0) {
        const options = {
          keys: ["name"],
        };
        const fuse = new Fuse(templates, options);
        return fuse.search(this._query).map((result) => result.item);
      }

      return templates;
    },
    args: () => [this.filterStore.filters.length, this._query],
  });

  setSearchInputValue(e: InputEvent) {
    const input = e.target as IonInput;
    this._query = input.value?.toString() || "";
  }

  hasFilters() {
    return this.filterStore.hasFilters || this._query.length > 0;
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-router-link href=${this.router.backUrl} router-direction="back">
                <ion-button
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </ion-router-link>
            </div>
          </ion-buttons>
          <ion-title>${this.#loadReport.value?.name}</ion-title>
          ${when(
            this.#loadReport.value?.canEdit(this.auth.user),
            () => html`
              <ion-buttons slot="end">
                <div class="flex items-center">
                  <ion-button
                    fill="solid"
                    style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                    color="secondary"
                    shape="round"
                    size="xsmall"
                    @click=${() => this.#modalEditReportLibraryRef.value?.open({ report: this.#loadReport.value })}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="mr-1 mb-0.5">
                      <path
                        d="M8.00033 5.33308L2.66699 10.6664V13.3331L5.33366 13.3331L10.667 7.99974M8.00033 5.33308L9.91274 3.42065L9.91389 3.41952C10.1772 3.15626 10.309 3.0244 10.461 2.97501C10.5949 2.93151 10.7392 2.93151 10.873 2.97501C11.0249 3.02436 11.1567 3.15608 11.4195 3.41896L12.5794 4.57882C12.8434 4.84283 12.9755 4.9749 13.0249 5.12712C13.0684 5.26101 13.0684 5.40524 13.0249 5.53914C12.9755 5.69125 12.8436 5.82312 12.58 6.08675L12.5794 6.08732L10.667 7.99974M8.00033 5.33308L10.667 7.99974"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    ${msg("Editar")}
                  </ion-button>
                </div>
              </ion-buttons>
            `,
          )}
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen style="--padding-bottom: 24px">
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            if (!this.auth.user) return;
            await this.#loadReport.run([false]);
            await this.#loadReportTemplates.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>

        <ion-header collapse="condense">
          <ion-toolbar>
            ${this.#loadReport.render({
              pending: () => {
                return html`
                  <div class="flex justify-between items-start">
                    <h3>
                      <ion-skeleton-text animated style="width: 52px; height: 1em;"></ion-skeleton-text>
                    </h3>
                    <div class="flex items-center space-x-1 mt-2">
                      <ion-skeleton-text animated style="width: 52px; height: 24px;"></ion-skeleton-text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        class="mb-0.5">
                        <path
                          d="M8 4H7.2002C6.08009 4 5.51962 4 5.0918 4.21799C4.71547 4.40973 4.40973 4.71547 4.21799 5.0918C4 5.51962 4 6.08009 4 7.2002V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H8M8 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H8M8 4V20M12 11H16M12 8H16"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                `;
              },
              error: (err) => {
                if (err instanceof ApiError) {
                  return html`<div
                    class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ee0000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                      <path d="M12 9v4" />
                      <path d="M12 17h.01" />
                    </svg>
                    <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                      ${err.message}
                    </span>
                  </div>`;
                }
              },
              complete: (report) => html`
                <div class="flex justify-between items-start">
                  <h3>${report.name}</h3>
                  <div class="flex items-center space-x-1 mt-2">
                    ${when(
                      this.hasFilters(),
                      () =>
                        this.#filterReportTemplates.render({
                          pending: () => html`
                            <ion-skeleton-text animated style="width: 24px; height: 1em;"></ion-skeleton-text>
                          `,
                          complete: (templates) => html`
                            <span class="font-display font-semibold">${templates.length}</span>
                          `,
                        }),

                      () => html`<span class="font-display font-semibold">${report?.totalTemplates}</span> `,
                    )}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      class="mb-0.5">
                      <path
                        d="M8 4H7.2002C6.08009 4 5.51962 4 5.0918 4.21799C4.71547 4.40973 4.40973 4.71547 4.21799 5.0918C4 5.51962 4 6.08009 4 7.2002V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H8M8 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H8M8 4V20M12 11H16M12 8H16"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              `,
            })}
          </ion-toolbar>
        </ion-header>

        <!-- --------- SEARCHBAR ------------ -->
        ${when(
          this.#loadReport.status !== TaskStatus.ERROR,
          () => html`
            <ion-item class="mt-2.5">
              <span aria-hidden="true" slot="start" class="flex items-center mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              <ion-input
                debounce=${150}
                @ionInput="${(e: InputEvent) => this.setSearchInputValue(e)}"
                clear-on-edit
                type="search"
                placeholder=${msg("Pesquisar avaliações")}></ion-input>
              <ion-button
                shape="round"
                size="chip"
                slot="end"
                @click=${() => {
                  this.#modalFilterReports.value?.open({
                    filterStore: this.filterStore,
                    report: this.#loadReport.value,
                  });
                }}>
                <span class="flex items-center mr-0.5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path
                      d="M11.6663 3.26672C11.6663 2.94002 11.6661 2.77655 11.6025 2.65177C11.5466 2.54201 11.4577 2.45283 11.348 2.39691C11.2232 2.33333 11.0595 2.33333 10.7328 2.33333H3.26611C2.93942 2.33333 2.77623 2.33333 2.65145 2.39691C2.54169 2.45283 2.45251 2.54201 2.39659 2.65177C2.33301 2.77655 2.33301 2.94002 2.33301 3.26672V3.69679C2.33301 3.83946 2.33301 3.91086 2.34913 3.97799C2.36342 4.03751 2.38704 4.09437 2.41903 4.14656C2.45509 4.20541 2.50562 4.25594 2.60645 4.35677L5.55971 7.31003C5.66059 7.41091 5.71076 7.46108 5.74684 7.51995C5.77882 7.57214 5.80277 7.62919 5.81706 7.68872C5.83301 7.75517 5.83301 7.82571 5.83301 7.9655V10.7397C5.83301 11.2398 5.83301 11.49 5.93832 11.6405C6.03029 11.772 6.17216 11.8597 6.33089 11.8832C6.51267 11.91 6.73642 11.7983 7.18368 11.5747L7.65034 11.3414C7.83763 11.2477 7.93104 11.2007 7.99946 11.1309C8.05996 11.0691 8.10625 10.9949 8.13444 10.9132C8.16632 10.8208 8.16634 10.7158 8.16634 10.5064V7.96984C8.16634 7.82717 8.16634 7.75585 8.18246 7.68872C8.19675 7.62919 8.22038 7.57214 8.25236 7.51995C8.28819 7.46148 8.33823 7.41144 8.43778 7.31189L8.43978 7.31003L11.393 4.35677C11.4939 4.25588 11.5441 4.20543 11.5802 4.14656C11.6122 4.09437 11.6361 4.03751 11.6504 3.97799C11.6663 3.91154 11.6663 3.84092 11.6663 3.70113V3.26672Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("Filtrar")}
              </ion-button>
            </ion-item>
          `,
        )}

        <!-- ------------- FILTERS ---------------- -->
        ${when(
          this.hasFilters(),
          () => html`
            <div class="flex items-center gap-x-1.5 gap-y-2 flex-wrap mt-2">
              ${repeat(
                this.filterStore.filters,
                (filter) => filter.id,
                (filter) =>
                  when(
                    filter.id !== "all",
                    () => html`
                      <ion-chip
                        color="primary"
                        class="px-2.5 py-1.5 text-sm font-medium"
                        @click=${() => this.filterStore.remove(filter)}>
                        ${filter.name}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          class="ml-1 mb-px">
                          <rect width="12" height="12" rx="6" fill="white" />
                          <path
                            d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </ion-chip>
                    `,
                  ),
              )}
            </div>
          `,
        )}

        <!-- -------------- CONTENT -------------- -->

        ${when(
          this.hasFilters(),
          () =>
            this.#filterReportTemplates.render({
              pending: () => {
                return html`
                  <ion-list class="mt-2.5">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </ion-list>
                `;
              },
              error: () => {
                return html`<div
                  class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ee0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                    ${msg("Erro ao pesquisar")}
                  </span>
                </div>`;
              },
              complete: (templates) => {
                if (templates.length === 0) {
                  return html`
                    <div
                      class="mt-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                          stroke="#111111"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                        ${msg("Não foram encontrados resultados")}
                      </span>
                    </div>
                  `;
                }

                return html`
                  <ion-list class="mt-2.5">
                    ${repeat(
                      templates,
                      (template) => template.id,
                      (template) =>
                        when(
                          template instanceof ObservableEvaluation,
                          () => html`
                            <ion-item
                              button
                              @click=${() =>
                                this.router.push(
                                  `reports/${this.router.getParam("reportId")}/evaluation/${template.id}`,
                                  "forward",
                                )}
                              .detail=${false}
                              style="--min-height: 52px; --max-height: 52px; --inner-padding-top: 10px; --inner-padding-bottom: 10px"
                              class="no-p">
                              <div class="w-full flex flex-col">
                                <div class="flex items-center gap-x-1.5">
                                  <p class="font-semibold line-clamp-1">${template.name}</p>
                                  <ion-badge color="success" class="font-semibold">${msg("Avaliação")}</ion-badge>
                                </div>
                                <div class="flex items-center space-x-[3px]">
                                  ${template.categories.slice(0, 2).map(
                                    (category) => html`
                                      <span class="text-sm">${category.category}</span>
                                      <span class="text-accent-6 last:hidden">•</span>
                                    `,
                                  )}
                                </div>
                              </div>
                            </ion-item>
                          `,
                          () => html`
                            <ion-item
                              button
                              @click=${() =>
                                this.router.push(
                                  `reports/${this.router.getParam("reportId")}/scale/${template.id}`,
                                  "forward",
                                )}
                              .detail=${false}
                              style="--min-height: 52px; --inner-padding-top: 10px; --inner-padding-bottom: 10px"
                              class="no-p">
                              <div class="w-full flex flex-col">
                                <div class="flex items-center gap-x-1.5">
                                  <p class="font-semibold line-clamp-1">${template.name}</p>

                                  <ion-badge color="secondary" class="font-semibold">${msg("Escala")}</ion-badge>
                                </div>
                                <div class="flex items-center space-x-[3px] ">
                                  ${template.categories.slice(0, 2).map(
                                    (category) => html`
                                      <span class="text-sm">${category.category}</span>
                                      <span class="text-accent-6 last:hidden">•</span>
                                    `,
                                  )}
                                </div>
                              </div>
                            </ion-item>
                          `,
                        ),
                    )}
                  </ion-list>
                `;
              },
            }),
          () =>
            this.#loadReportTemplates.render({
              pending: () => {
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              },
              error: (err) => {
                if (err instanceof ApiError) {
                  return html`<div
                    class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ee0000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round">
                      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                      <path d="M12 9v4" />
                      <path d="M12 17h.01" />
                    </svg>
                    <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                      ${err.message}
                    </span>
                  </div>`;
                }
              },
              complete: () => {
                const templates = this.#loadReport.value?.allTemplates;
                if (!templates) return nothing;
                return html`
                  ${when(
                    templates.length === 0,
                    () => {
                      if (this.#loadReport.value?.canEdit(this.auth.user)) {
                        return html`
                          <ion-item
                            @click=${() => this.#modalNewReportRef.value?.open()}
                            class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                            <div
                              class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none">
                                <path
                                  d="M6 12H12M12 12H18M12 12V18M12 12V6"
                                  stroke="#111111"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                              <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                                ${msg("Cria a tua primeira avaliação ou escala")}
                              </span>
                            </div>
                          </ion-item>
                        `;
                      } else {
                        return html`
                          <div
                            class="mt-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                                stroke="#111111"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                            <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                              ${msg("Esta biblioteca ainda não tem conteúdo")}
                            </span>
                          </div>
                        `;
                      }
                    },
                    () => {
                      return html`
                        <!-- ------------- CONTENT ------------- -->
                        <ion-list class="mt-2.5">
                          ${repeat(
                            templates,
                            (template) => template.id,
                            (template) =>
                              when(
                                template instanceof ObservableEvaluation,
                                () => html`
                                  <ion-item
                                    button
                                    @click=${() =>
                                      this.router.push(
                                        `reports/${this.router.getParam("reportId")}/evaluation/${template.id}`,
                                        "forward",
                                      )}
                                    .detail=${false}
                                    style="--min-height: 52px; --inner-padding-top: 10px; --inner-padding-bottom: 10px"
                                    class="no-p">
                                    <div class="w-full flex flex-col">
                                      <div class="flex items-center gap-x-1.5">
                                        <p class="font-semibold line-clamp-1">${template.name}</p>
                                        <ion-badge color="success" class="font-semibold">${msg("Avaliação")}</ion-badge>
                                      </div>
                                      <div class="flex items-center space-x-[3px]">
                                        ${template.categories.slice(0, 2).map(
                                          (category) => html`
                                            <span class="text-sm">${category.category}</span>
                                            <span class="text-accent-6 last:hidden">•</span>
                                          `,
                                        )}
                                      </div>
                                    </div>
                                  </ion-item>
                                `,
                                () => html`
                                  <ion-item
                                    button
                                    @click=${() =>
                                      this.router.push(
                                        `reports/${this.router.getParam("reportId")}/scale/${template.id}`,
                                        "forward",
                                      )}
                                    .detail=${false}
                                    style="--min-height: 52px; --inner-padding-top: 10px; --inner-padding-bottom: 10px"
                                    class="no-p">
                                    <div class="w-full flex flex-col">
                                      <div class="flex items-center gap-x-1.5">
                                        <p class="font-semibold line-clamp-1">${template.name}</p>

                                        <ion-badge color="secondary" class="font-semibold">${msg("Escala")}</ion-badge>
                                      </div>
                                      <div class="flex items-center space-x-[3px]">
                                        ${template.categories.slice(0, 2).map(
                                          (category) => html`
                                            <span class="text-sm">${category.category}</span>
                                            <span class="text-accent-6 last:hidden">•</span>
                                          `,
                                        )}
                                      </div>
                                    </div>
                                  </ion-item>
                                `,
                              ),
                          )}
                        </ion-list>
                      `;
                    },
                  )}
                  <!-- ------------FAB------------ -->
                  ${when(
                    this.#loadReport.value?.canEdit(this.auth.user),
                    () => html`
                      <ion-fab
                        slot="fixed"
                        vertical="bottom"
                        horizontal="end"
                        @click=${() => this.#modalNewReportRef.value?.open()}>
                        <ion-fab-button id="open-modal-create-report" label="button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none">
                            <path
                              d="M6 12H12M12 12H18M12 12V18M12 12V6"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </ion-fab-button>
                      </ion-fab>
                    `,
                  )}
                `;
              },
            }),
        )}
      </ion-content>

      <modal-nav root="modal-create-report" ${ref(this.#modalNewReportRef)}></modal-nav>
      <modal-nav root="modal-filter-reports" ${ref(this.#modalFilterReports)}></modal-nav>
      <modal-nav root="modal-edit-report-library" ${ref(this.#modalEditReportLibraryRef)}></modal-nav>
    `;
  }
}
