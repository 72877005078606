import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { Page } from "../components/component";
import { Task } from "@lit/task";
import { consume } from "@lit/context";
import { routerContext, RouterContext } from "../context/router.context";
import { reportRepository } from "../repository/report/report.repository";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import type { ObservableSession } from "../stores/sessions.store";
import { localized, msg, str } from "@lit/localize";

@customElement("modal-choose-report")
@localized()
export class ModalChooseReport extends Page {
  session!: ObservableSession;
  patientId?: string;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  #reports = new Task(this, {
    task: async () => {
      return await reportRepository.listReports();
    },
    args: () => [],
  });

  render() {
    return html`
      <ion-content fullscreen style="--padding-bottom: 24px">
        <!-- ----------- HEADER --------------- -->
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => {
                    this.navigator.close();
                  }}">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">${msg("Templates")}</ion-title>
          </ion-toolbar>
        </ion-header>

        ${this.#reports.render({
          pending: () => {
            return html`
              <div class="space-y-4">
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
              </div>
            `;
          },
          error: () => {
            return html` <span class="text-danger">${msg("Erro ao carregar as avaliações")}</span> `;
          },
          complete: (reports) => {
            return html`
              <ion-list lines="none" class="space-y-4 mt-2.5 flex flex-col">
                ${reports.map((report) => {
                  return html`
                    <ion-item
                      button
                      .detail=${false}
                      style="--border-width: 1px; --border-color: #EAEAEA; --border-radius: 6px"
                      class="no-p no-inner-p"
                      @click=${() =>
                        this.navigator.push("modal-choose-evaluation", {
                          session: this.session,
                          report: report,
                          patientId: this.patientId,
                        })}>
                      <div
                        class="w-full h-[140px] bg-accent-8 flex col justify-center items-center space-y-0.5 relative">
                        <p class="font-display font-semibold text-accent-1">${report.name}</p>
                        <ion-badge class="px-1.5 py-0.5 text-xs font-semibold"
                          >${msg(str`${report.totalEvaluations} avaliações`)}</ion-badge
                        >
                      </div>
                    </ion-item>
                  `;
                })}
              </ion-list>
            `;
          },
        })}
      </ion-content>
    `;
  }
}
