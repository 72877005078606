import { html } from "lit";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { repeat } from "lit/directives/repeat.js";
import { librariesStore } from "../stores/libraries.store";
import { Task } from "@lit/task";
import { Page, required } from "../components/component";
import type { FilterStore } from "../stores/filter.store";
import { customElement } from "../element";
import { localized, msg } from "@lit/localize";

@customElement("modal-exercise-public-filters")
@localized()
export class ModalExercisePublicFilters extends Page {
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  @required({ type: Object }) private filterStore!: FilterStore;

  #loadFilters = new Task(this, {
    task: async () => {
      return await librariesStore.loadPublicFilters();
    },
    args: () => [],
  });

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m"
                @click="${() => {
                  this.filterStore.rollback();
                  this.navigator.goBack();
                }}">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Aplicar Filtros")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4" fullscreen>
        <!-- spacer -->
        <div class="mt-2.5">
          <!-- --------------- CONTENT ----------------- -->
          ${this.#loadFilters.render({
            pending: () => {
              return html`
                <div class="space-y-8">
                  <div>
                    <ion-skeleton-text animated="true" style="width: 30%; height: 24px;"></ion-skeleton-text>
                    <div class="mt-2 space-y-2">
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <div>
                    <ion-skeleton-text animated="true" style="width: 30%; height: 24px;"></ion-skeleton-text>
                    <div class="mt-2 space-y-2">
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <div>
                    <ion-skeleton-text animated="true" style="width: 30%; height: 24px;"></ion-skeleton-text>
                    <div class="mt-2 space-y-2">
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <div>
                    <ion-skeleton-text animated="true" style="width: 30%; height: 24px;"></ion-skeleton-text>
                    <div class="mt-2 space-y-2">
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                    </div>
                  </div>
                </div>
              `;
            },
            error: () => {
              return html`<span class="text-danger">${msg("Erro ao carregar filtros")}</span>`;
            },
            complete: (filters) => {
              return html`
                <div class="space-y-8">
                  <div class="space-y-2">
                    <p class="font-semibold font-display">${msg("Por categoria")}</p>
                    <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                      ${repeat(
                        filters.categories,
                        (filter) => filter.id,
                        (filter) => {
                          const f = { id: filter.id, name: filter.category };
                          const isSelected = this.filterStore.isActive(f);
                          return html`
                            <ion-chip
                              color=${isSelected ? "primary" : "light"}
                              @click=${() => this.filterStore.stage("categories", f)}
                              class="px-2.5 py-1.5 text-sm font-medium">
                              ${filter.category}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                class=${isSelected ? "ml-1 mb-px" : "hidden ml-1 mb-px"}>
                                <rect width="12" height="12" rx="6" fill="white" />
                                <path
                                  d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                  stroke="black"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </ion-chip>
                          `;
                        },
                      )}
                    </div>
                  </div>
                  <div class="space-y-2">
                    <p class="font-semibold font-display">${msg("Músculo")}</p>
                    <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                      ${repeat(
                        filters.muscles,
                        (filter) => filter.id,
                        (filter) => {
                          const f = { id: filter.id, name: filter.muscle };
                          const isSelected = this.filterStore.isActive(f);
                          return html`
                            <ion-chip
                              color=${isSelected ? "primary" : "light"}
                              @click=${() => this.filterStore.stage("muscles", f)}
                              class="px-2.5 py-1.5 text-sm font-medium">
                              ${filter.muscle}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                class=${isSelected ? "ml-1 mb-px" : "hidden ml-1 mb-px"}>
                                <rect width="12" height="12" rx="6" fill="white" />
                                <path
                                  d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                  stroke="black"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </ion-chip>
                          `;
                        },
                      )}
                    </div>
                  </div>
                  <div class="space-y-2">
                    <p class="font-semibold font-display">${msg("Dificuldade")}</p>
                    <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                      ${repeat(
                        filters.difficulties,
                        (filter) => filter.id,
                        (filter) => {
                          const f = { id: filter.id, name: filter.difficulty };
                          const isSelected = this.filterStore.isActive(f);
                          return html`
                            <ion-chip
                              color=${isSelected ? "primary" : "light"}
                              @click=${() => this.filterStore.stage("difficulties", f)}
                              class="px-2.5 py-1.5 text-sm font-medium">
                              ${filter.difficulty}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                class=${isSelected ? "ml-1 mb-px" : "hidden ml-1 mb-px"}>
                                <rect width="12" height="12" rx="6" fill="white" />
                                <path
                                  d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                  stroke="black"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </ion-chip>
                          `;
                        },
                      )}
                    </div>
                  </div>
                  <div class="space-y-2">
                    <p class="font-semibold font-display">${msg("Equipamento")}</p>
                    <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                      ${repeat(
                        filters.equipments,
                        (filter) => filter.id,
                        (filter) => {
                          const f = { id: filter.id, name: filter.equipment };
                          const isSelected = this.filterStore.isActive(f);
                          return html`
                            <ion-chip
                              color=${isSelected ? "primary" : "light"}
                              @click=${() => this.filterStore.stage("equipments", f)}
                              class="px-2.5 py-1.5 text-sm font-medium">
                              ${filter.equipment}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                class=${isSelected ? "ml-1 mb-px" : "hidden ml-1 mb-px"}>
                                <rect width="12" height="12" rx="6" fill="white" />
                                <path
                                  d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                  stroke="black"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </ion-chip>
                          `;
                        },
                      )}
                    </div>
                  </div>
                </div>
              `;
            },
          })}
        </div>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            shape="round"
            expand="block"
            class="font-semibold"
            @click=${() => {
              this.filterStore.commit();
              this.navigator.goBack();
            }}
            >${msg("Filtrar Exercícios")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
