import type { IonInput } from "@ionic/core/components/ion-input";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import Fuse from "fuse.js";
import { html } from "lit";
import { state } from "lit/decorators.js";
import { live } from "lit/directives/live.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { Page, required } from "../components/component";
import { RouterContext, routerContext } from "../context/router.context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { customElement } from "../element";
import { libraryRepository } from "../repository/library/library.repository";
import type { FilterStore } from "../stores/filter.store";
import type { ModalSheetNav } from "./modal-sheet-nav";
import { alertController } from "@ionic/core";
import type { Difficulty } from "../repository/library/exercises";
import { localized, msg, str } from "@lit/localize";
import type { ObservableLibrary } from "../stores/libraries.store";
import type { ErrorUnknown } from "../error";
import { ErrorLibraryDifficultyNotFound } from "../repository/library/errors";

const asFilter = (difficulty: Difficulty) => ({ id: difficulty.id, name: difficulty.difficulty });

@customElement("modal-library-difficulties")
@localized()
export class ModalLibraryDifficulties extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;
  @consume({ context: routerContext }) router!: RouterContext;

  #modalCreateDifficultyRef: Ref<ModalSheetNav> = createRef();

  FILTER_KEY = "difficulties";

  @required() filterStore!: FilterStore;
  library?: ObservableLibrary;

  @state() private _query = "";
  @state() private _difficulties: Difficulty[] = [];
  @state() private _filteredDifficulties: Difficulty[] = [];

  #difficulties = new Task(this, {
    task: async () => {
      const libraryId = this.library ? this.library.id : this.router.getParam("id");
      this._difficulties = await libraryRepository.getLibraryDifficulties(libraryId);
      return this._difficulties;
    },
    args: () => [],
  });

  setSearchInputValue(e: InputEvent) {
    const input = e.target as IonInput;
    this._query = input.value?.toString() || "";

    if (this._query.length > 0) {
      const options = {
        keys: ["difficulty"],
      };
      if (this.#difficulties.value) {
        const fuse = new Fuse(this._difficulties, options);
        this._filteredDifficulties = fuse.search(this._query).map((result) => result.item);
      }
    } else {
      this._filteredDifficulties = [];
    }
  }

  async removeDifficulty(difficulty: Difficulty) {
    const count = difficulty.count || 0;
    if (count > 0) {
      alertController
        .create({
          header: msg("Remover Dificuldade"),
          message: msg(
            str`A dificuldade "${difficulty.difficulty}" está a ser usada em ${count} exercícios. Deseja continuar?`,
          ),
          buttons: [
            {
              text: msg("Cancelar"),
              role: "cancel",
            },
            {
              text: msg("Remover"),
              role: "destructive",
              handler: () => {
                this.deleteDifficulty(difficulty);
              },
            },
          ],
        })
        .then((alert) => alert.present());
    } else {
      this.deleteDifficulty(difficulty);
    }
  }

  async deleteDifficulty(difficulty: Difficulty) {
    this.filterStore.remove(asFilter(difficulty));
    try {
      await libraryRepository.removeDifficulty(difficulty.id);
      this._difficulties = this._difficulties.filter((m) => m.id !== difficulty.id);
      this._filteredDifficulties = this._filteredDifficulties.filter((m) => m.id !== difficulty.id);
      this.requestUpdate();
    } catch (error) {
      if (error instanceof ErrorLibraryDifficultyNotFound) {
        const alert = await alertController.create({
          header: msg("Erro"),
          message: error.message,
          buttons: ["OK"],
        });
        await alert.present();
      } else {
        const alert = await alertController.create({
          header: msg("Erro"),
          message: (error as ErrorUnknown).message,
          buttons: ["OK"],
        });
        await alert.present();
      }
    }
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              @click=${() => {
                this.filterStore.rollback();
                this.navigator.goBack();
              }}
              class="font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="-ml-2 mr-1">
                <path
                  d="M14 16L10 12L14 8"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              ${msg("voltar")}</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">${msg("Dificuldades")}</ion-title>
          <ion-buttons slot="end">
            <ion-button
              @click="${() => {
                this.filterStore.commit();
                this.navigator.goBack();
              }}"
              class="font-semibold"
              >${msg("Confirmar")}</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4" fullscreen>
        <ion-item class="mt-6">
          <span aria-hidden="true" slot="start" class="flex items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <ion-input
            id="search-difficulty"
            clear-on-edit
            type="search"
            clear-input
            placeholder=${msg("Pesquisar dificuldades...")}
            .value=${live(this._query)}
            @ionInput="${(e: InputEvent) => this.setSearchInputValue(e)}"></ion-input>
        </ion-item>

        <div class="mt-4">
          <div class="mb-2 font-semibold">${this._query}</div>
          <ion-button
            color="secondary"
            expand="block"
            size="small"
            shape="round"
            class="font-bold"
            @click="${() =>
              this.#modalCreateDifficultyRef.value?.open({
                difficulty: this._query,
                onSubmit: async (difficulty: Difficulty) => {
                  this._difficulties = [...this._difficulties, difficulty];
                  this._query = "";
                  this.requestUpdate();
                },
              })}">
            ${msg("Criar Dificuldade")}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="ml-2">
              <path
                d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </ion-button>
        </div>

        ${when(
          this._query.length > 0,
          () => {
            return html`
              <ion-list class="mt-4">
                ${this._filteredDifficulties.map((difficulty, index) => {
                  const isSelected = this.filterStore.isActive(asFilter(difficulty));
                  return html`
                    <ion-item
                      id=${index}
                      button
                      lines=${index < this._filteredDifficulties.length - 1 ? "full" : "none"}
                      .detail=${false}
                      detail-icon="/assets/icons/check.svg"
                      class="no-p no-inline-p"
                      @click="${() => {
                        this.filterStore.stage(this.FILTER_KEY, asFilter(difficulty));
                      }}"
                      style="--detail-icon-color: var(--ion-color-success); --detail-icon-opacity: 1;">
                      <div class="py-4 w-full flex items-center justify-between">
                        <ion-label class="font-semibold">${difficulty.difficulty}</ion-label>
                        ${when(!isSelected, () => html` <ion-badge>${difficulty.count}</ion-badge> `)}
                      </div>
                    </ion-item>
                  `;
                })}
              </ion-list>
            `;
          },
          () => {
            return html`
              <ion-list>
                ${this.#difficulties.render({
                  pending: () =>
                    html` ${Array.from(
                      { length: 5 },
                      () => html`
                        <ion-item class="no-p no-inline-p">
                          <div class="py-4 w-full flex items-center justify-between">
                            <ion-skeleton-text animated style="width: 50%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 10%; height: 20px"></ion-skeleton-text>
                          </div>
                        </ion-item>
                      `,
                    )}`,
                  error: () => {
                    return html` <span class="text-danger">${msg("Erro ao carregar as difficuldades")}</span>`;
                  },
                  complete: () => html`
                    ${repeat(
                      this._difficulties,
                      (difficulty) => difficulty.id,
                      (difficulty) => {
                        const isSelected = this.filterStore.isActive(asFilter(difficulty));
                        return html`
                          <ion-item-sliding>
                            <ion-item
                              button
                              .detail=${isSelected}
                              detail-icon="/assets/icons/check.svg"
                              class="no-p no-inline-p"
                              @click=${() => {
                                this.filterStore.stage(this.FILTER_KEY, asFilter(difficulty));
                              }}
                              style="--detail-icon-color: var(--ion-color-success); --detail-icon-opacity: 1;">
                              <div class="py-4 w-full flex items-center justify-between">
                                <ion-label class="font-semibold">${difficulty.difficulty}</ion-label>
                                ${when(!isSelected, () => html` <ion-badge>${difficulty.count}</ion-badge> `)}
                              </div>
                            </ion-item>

                            <ion-item-options>
                              <ion-item-option>
                                <ion-button
                                  @click=${(e: Event) => {
                                    this.removeDifficulty(difficulty);
                                    // @ts-ignore
                                    e?.target?.closest("ion-item-sliding")?.close();
                                  }}
                                  size="chip"
                                  color="danger"
                                  shape="round"
                                  class="open-move-patient-modal"
                                  style="--padding-start: 8px; --padding-end: 8px;"
                                  >${msg("Remover")}</ion-button
                                >
                              </ion-item-option>
                            </ion-item-options>
                          </ion-item-sliding>
                        `;
                      },
                    )}
                  `,
                })}
              </ion-list>
            `;
          },
        )}
      </ion-content>

      <modal-sheet-nav
        auto-height
        root="modal-create-exercise-difficulty"
        ${ref(this.#modalCreateDifficultyRef)}></modal-sheet-nav>
    `;
  }
}
