import { z } from "zod";
import { UserSchema } from "../user/user";
import { pb } from "../../pocketbase";
import { record } from "../shared/record";
import { unsafeParse } from "../shared/unsafeParse";
import { AccessSchema } from "../group/access";
import { file } from "../shared/file";
import { msg } from "@lit/localize";

export const GroupRecordSchema = record({
  id: z.string(),
  name: z.string(),
  owner: z.string(),
  isArchived: z.boolean(),
});

export enum EvaluationFieldType {
  Attachment = "evaluation_field_attachment",
  NumericBox = "evaluation_field_numeric_box",
  ResultInteger = "evaluation_field_result_integer",
  ResultPercentage = "evaluation_field_result_percentage",
  TextBox = "evaluation_field_text_box",
  Scale = "evaluation_field_scale",
  Url = "evaluation_field_url",
  YesOrNo = "evaluation_field_yes_or_no",
}

/* ********************** Categories ************************** */
export const ReportCategoryRecordSchema = record({
  id: z.string(),
  report: z.string(),
  category: z.string(),
});
export interface ReportCategoryRecord extends z.infer<typeof ReportCategoryRecordSchema> {}

export const ReportCategorySchema = ReportCategoryRecordSchema.extend({
  count: z.number().optional(),
}).omit({ expand: true });
export interface Category extends z.infer<typeof ReportCategorySchema> {}
export function createReportCategory(data: unknown) {
  return unsafeParse(ReportCategorySchema, data);
}

export const CreateReportCategorySchema = z.object({
  report: z.string(),
  category: z.string(),
});
export interface CreateCategory extends z.infer<typeof CreateReportCategorySchema> {}

/********************************************************************
 *************************** SCALES ********************************
 ********************************************************************/

/* *********************** Scales Options ********************** */
export const ScaleOptionRecordSchema = record({
  id: z.string(),
  field: z.string(),
  scale: z.string(),
  label: z.string(),
  value: z.number(),
});
export interface ScaleOptionRecord extends z.infer<typeof ScaleOptionRecordSchema> {}

export const ScaleOptionSchema = ScaleOptionRecordSchema.omit({ expand: true });
export interface ScaleOption extends z.infer<typeof ScaleOptionSchema> {}
export function createScaleOption(data: unknown) {
  return unsafeParse(ScaleOptionSchema, data);
}

export const CreateScaleOptionSchema = () =>
  z.object({
    field: z.string(),
    scale: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
    value: z.coerce.number({
      required_error: msg("O valor é obrigatório"),
      invalid_type_error: msg("O valor deve ser um número"),
    }),
  });
export interface CreateScaleOption extends z.infer<ReturnType<typeof CreateScaleOptionSchema>> {}

export const UpdateScaleOptionSchema = () =>
  z.object({
    label: z.string().min(1, msg("A legenda é obrigatória")).optional(),
    value: z.coerce.number({ invalid_type_error: msg("O valor deve ser um número") }).optional(),
  });
export interface UpdateScaleOption extends z.infer<ReturnType<typeof UpdateScaleOptionSchema>> {}

/* *********************** Scales Fields ********************** */
export const ScaleFieldRecordSchema = record({
  id: z.string(),
  scale: z.string(),
  label: z.string(),
  position: z.number(),
});
export interface ScaleFieldRecord extends z.infer<typeof ScaleFieldRecordSchema> {}

export const ScaleFieldSummarySchema = ScaleFieldRecordSchema.omit({ expand: true });
export interface ScaleFieldSummary extends z.infer<typeof ScaleFieldSummarySchema> {}
export function createScaleFieldSummary(data: unknown) {
  return unsafeParse(ScaleFieldSummarySchema, data);
}

export const ScaleFieldSchema = ScaleFieldSummarySchema.extend({
  options: z.array(ScaleOptionSchema).default([]),
});
export interface ScaleField extends z.infer<typeof ScaleFieldSchema> {}
export function createScaleField(data: unknown) {
  return unsafeParse(ScaleFieldSchema, data);
}

export const CreateScaleFieldSchema = () =>
  z.object({
    scale: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
    position: z.number(),
  });
export interface CreateScaleField extends z.infer<ReturnType<typeof CreateScaleFieldSchema>> {}

export const UpdateScaleFieldSchema = () =>
  z.object({
    label: z.string().min(1, msg("A legenda é obrigatória")).optional(),
    position: z.number().optional(),
  });
export interface UpdateScaleField extends z.infer<ReturnType<typeof UpdateScaleFieldSchema>> {}

/* *********************** Scales Entity ********************** */
export const ScaleRecordSchema = record({
  id: z.string(),
  report: z.string(),
  name: z.string(),
  expression: z.string(),
});
export interface ScaleRecord extends z.infer<typeof ScaleRecordSchema> {}

export const ScaleSummarySchema = ScaleRecordSchema.extend({
  categories: z.array(ReportCategorySchema).default([]),
}).omit({ expand: true });
export interface ScaleSummary extends z.infer<typeof ScaleSummarySchema> {}
export function createScaleSummary(data: unknown) {
  return unsafeParse(ScaleSummarySchema, data);
}

export const ScaleSchema = ScaleSummarySchema.extend({
  fields: z.array(ScaleFieldSchema).default([]),
});
export interface Scale extends z.infer<typeof ScaleSchema> {}
export function createScale(data: unknown) {
  return unsafeParse(ScaleSchema, data);
}

export const CreateScaleSchema = z.object({
  report: z.string(),
  name: z.string(),
  expression: z.string(),
  categories: z.array(z.string()).optional(),
});
export interface CreateScale extends z.infer<typeof CreateScaleSchema> {}

export const UpdateScaleSchema = z.object({
  name: z
    .string({ required_error: msg("O nome é obrigatório") })
    .min(3, msg("O nome deve ter no mínimo 3 caracteres"))
    .max(64, msg("O nome deve ter no máximo 64 caracteres")).optional(),
  expression: z.string().optional(),
  categories: z.array(z.string()).optional(),
  "categories-": z.array(z.string()).optional(),
});
export interface UpdateScale extends z.infer<typeof UpdateScaleSchema> {}

/********************************************************************
 *************************** EVALUATIONS ********************************
 ********************************************************************/

/* *********************** Evaluations Fields ********************** */

/* *********************** Attachment ********************** */
export const EvaluationFieldAttachmentRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  label: z.string(),
  collectionName: z.literal(EvaluationFieldType.Attachment),
});
export interface EvaluationFieldAttachmentRecord extends z.infer<typeof EvaluationFieldAttachmentRecordSchema> {}
export const EvaluationFieldAttachmentSchema = EvaluationFieldAttachmentRecordSchema.omit({ expand: true });
export interface EvaluationFieldAttachment extends z.infer<typeof EvaluationFieldAttachmentSchema> {}
export function createEvaluationFieldAttachment(data: unknown) {
  return unsafeParse(EvaluationFieldAttachmentSchema, data);
}
export const CreateEvaluationFieldAttachmentSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
  });
export interface CreateEvaluationFieldAttachment
  extends z.infer<ReturnType<typeof CreateEvaluationFieldAttachmentSchema>> {}
export const UpdateEvaluationFieldAttachmentSchema = () =>
  z.object({
    label: z.string({ required_error: msg("A legenda é obrigatória") }).min(1, msg("A legenda é obrigatória")),
  });
export interface UpdateEvaluationFieldAttachment
  extends z.infer<ReturnType<typeof UpdateEvaluationFieldAttachmentSchema>> {}

/* *********************** Numeric Box ********************** */

export const EvaluationFieldNumericBoxRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  label: z.string(),
  collectionName: z.literal(EvaluationFieldType.NumericBox),
});
export interface EvaluationFieldNumericBoxRecord extends z.infer<typeof EvaluationFieldNumericBoxRecordSchema> {}
export const EvaluationFieldNumericBoxSchema = EvaluationFieldNumericBoxRecordSchema.omit({ expand: true });
export interface EvaluationFieldNumericBox extends z.infer<typeof EvaluationFieldNumericBoxSchema> {}
export function createEvaluationFieldNumericBox(data: unknown) {
  return unsafeParse(EvaluationFieldNumericBoxSchema, data);
}
export const CreateEvaluationFieldNumericBoxSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
  });
export interface CreateEvaluationFieldNumericBox
  extends z.infer<ReturnType<typeof CreateEvaluationFieldNumericBoxSchema>> {}
export const UpdateEvaluationFieldNumericBoxSchema = () =>
  z.object({
    label: z.string({ required_error: msg("A legenda é obrigatória") }).min(1, msg("A legenda é obrigatória")),
  });
export interface UpdateEvaluationFieldNumericBox
  extends z.infer<ReturnType<typeof UpdateEvaluationFieldNumericBoxSchema>> {}

/* *********************** Result Integer ********************** */

export const EvaluationFieldResultIntegerRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  label: z.string(),
  minimum: z.number(),
  maximum: z.number(),
  collectionName: z.literal(EvaluationFieldType.ResultInteger),
});
export interface EvaluationFieldResultIntegerRecord extends z.infer<typeof EvaluationFieldResultIntegerRecordSchema> {}
export const EvaluationFieldResultIntegerSchema = EvaluationFieldResultIntegerRecordSchema.omit({ expand: true });
export interface EvaluationFieldResultInteger extends z.infer<typeof EvaluationFieldResultIntegerSchema> {}
export function createEvaluationFieldResultInteger(data: unknown) {
  return unsafeParse(EvaluationFieldResultIntegerSchema, data);
}
export const CreateEvaluationFieldResultIntegerSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
    minimum: z.coerce.number({
      required_error: msg("O valor mínimo é obrigatório"),
      invalid_type_error: msg("O valor mínimo deve ser um número"),
    }),
    maximum: z.coerce.number({
      required_error: msg("O valor máximo é obrigatório"),
      invalid_type_error: msg("O valor máximo deve ser um número"),
    }),
  });
export interface CreateEvaluationFieldResultInteger
  extends z.infer<ReturnType<typeof CreateEvaluationFieldResultIntegerSchema>> {}
export const UpdateEvaluationFieldResultIntegerSchema = () =>
  z.object({
    label: z.string().min(1, msg("A legenda é obrigatória")).optional(),
    minimum: z.coerce.number().optional(),
    maximum: z.coerce.number().optional(),
  });
export interface UpdateEvaluationFieldResultInteger
  extends z.infer<ReturnType<typeof UpdateEvaluationFieldResultIntegerSchema>> {}

/* *********************** Result Percentage ********************** */

export const EvaluationFieldResultPercentageRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  label: z.string(),
  minimum: z.number(),
  maximum: z.number(),
  collectionName: z.literal(EvaluationFieldType.ResultPercentage),
});
export interface EvaluationFieldResultPercentageRecord
  extends z.infer<typeof EvaluationFieldResultPercentageRecordSchema> {}
export const EvaluationFieldResultPercentageSchema = EvaluationFieldResultPercentageRecordSchema.omit({ expand: true });
export interface EvaluationFieldResultPercentage extends z.infer<typeof EvaluationFieldResultPercentageSchema> {}
export function createEvaluationFieldResultPercentage(data: unknown) {
  return unsafeParse(EvaluationFieldResultPercentageSchema, data);
}
export const CreateEvaluationFieldResultPercentageSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
    minimum: z.coerce.number({
      required_error: msg("O valor mínimo é obrigatório"),
      invalid_type_error: msg("O valor mínimo deve ser um número"),
    }),
    maximum: z.coerce.number({
      required_error: msg("O valor máximo é obrigatório"),
      invalid_type_error: msg("O valor máximo deve ser um número"),
    }),
  });
export interface CreateEvaluationFieldResultPercentage
  extends z.infer<ReturnType<typeof CreateEvaluationFieldResultPercentageSchema>> {}
export const UpdateEvaluationFieldResultPercentageSchema = () =>
  z.object({
    label: z.string().min(1, msg("A legenda é obrigatória")).optional(),
    minimum: z.coerce.number().optional(),
    maximum: z.coerce.number().optional(),
  });
export interface UpdateEvaluationFieldResultPercentage
  extends z.infer<ReturnType<typeof UpdateEvaluationFieldResultPercentageSchema>> {}

/* *********************** Scale ********************** */

export const EvaluationFieldScaleRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  scale: z.string(),
  collectionName: z.literal(EvaluationFieldType.Scale),
});
export interface EvaluationFieldScaleRecord extends z.infer<typeof EvaluationFieldScaleRecordSchema> {}
export const EvaluationFieldScaleSchema = EvaluationFieldScaleRecordSchema.extend({
  scale: ScaleSummarySchema,
}).omit({ expand: true });
export interface EvaluationFieldScale extends z.infer<typeof EvaluationFieldScaleSchema> {}
export function createEvaluationFieldScale(data: unknown) {
  return unsafeParse(EvaluationFieldScaleSchema, data);
}
export const CreateEvaluationFieldScaleSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    scale: z.string({ required_error: msg("A escala é obrigatória") }),
  });
export interface CreateEvaluationFieldScale extends z.infer<ReturnType<typeof CreateEvaluationFieldScaleSchema>> {}

/* *********************** Text Box ********************** */

export const EvaluationFieldTextBoxRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  label: z.string(),
  collectionName: z.literal(EvaluationFieldType.TextBox),
});
export interface EvaluationFieldTextBoxRecord extends z.infer<typeof EvaluationFieldTextBoxRecordSchema> {}
export const EvaluationFieldTextBoxSchema = EvaluationFieldTextBoxRecordSchema.omit({ expand: true });
export interface EvaluationFieldTextBox extends z.infer<typeof EvaluationFieldTextBoxSchema> {}
export function createEvaluationFieldTextBox(data: unknown) {
  return unsafeParse(EvaluationFieldTextBoxSchema, data);
}
export const CreateEvaluationFieldTextBoxSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
  });
export interface CreateEvaluationFieldTextBox extends z.infer<ReturnType<typeof CreateEvaluationFieldTextBoxSchema>> {}
export const UpdateEvaluationFieldTextBoxSchema = () =>
  z.object({
    label: z.string({ required_error: msg("A legenda é obrigatória") }).min(1, msg("A legenda é obrigatória")),
  });
export interface UpdateEvaluationFieldTextBox extends z.infer<ReturnType<typeof UpdateEvaluationFieldTextBoxSchema>> {}

/* *********************** URL ********************** */

export const EvaluationFieldUrlRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  label: z.string(),
  collectionName: z.literal(EvaluationFieldType.Url),
});
export interface EvaluationFieldUrlRecord extends z.infer<typeof EvaluationFieldUrlRecordSchema> {}
export const EvaluationFieldUrlSchema = EvaluationFieldUrlRecordSchema.omit({ expand: true });
export interface EvaluationFieldUrl extends z.infer<typeof EvaluationFieldUrlSchema> {}
export function createEvaluationFieldUrl(data: unknown) {
  return unsafeParse(EvaluationFieldUrlSchema, data);
}
export const CreateEvaluationFieldUrlSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
  });
export interface CreateEvaluationFieldUrl extends z.infer<ReturnType<typeof CreateEvaluationFieldUrlSchema>> {}
export const UpdateEvaluationFieldUrlSchema = () =>
  z.object({
    label: z.string({ required_error: msg("A legenda é obrigatória") }).min(1, msg("A legenda é obrigatória")),
  });
export interface UpdateEvaluationFieldUrl extends z.infer<ReturnType<typeof UpdateEvaluationFieldUrlSchema>> {}

/* *********************** Yes or No ********************** */

export const EvaluationFieldYesOrNoRecordSchema = record({
  id: z.string(),
  section: z.string(),
  evaluation: z.string(),
  label: z.string(),
  option1: z.string(),
  option2: z.string(),
  option3: z.string().optional(),
  option4: z.string().optional(),
  option5: z.string().optional(),
  collectionName: z.literal(EvaluationFieldType.YesOrNo),
});
export interface EvaluationFieldYesOrNoRecord extends z.infer<typeof EvaluationFieldYesOrNoRecordSchema> {}
export const EvaluationFieldYesOrNoSchema = EvaluationFieldYesOrNoRecordSchema.omit({ expand: true });
export interface EvaluationFieldYesOrNo extends z.infer<typeof EvaluationFieldYesOrNoSchema> {}
export function createEvaluationFieldYesOrNo(data: unknown) {
  return unsafeParse(EvaluationFieldYesOrNoSchema, data);
}
export const CreateEvaluationFieldYesOrNoSchema = () =>
  z.object({
    section: z.string(),
    evaluation: z.string(),
    label: z.string({ required_error: msg("A legenda é obrigatória") }),
    option1: z.string({ required_error: msg("A opção é obrigatória") }),
    option2: z.string({ required_error: msg("A opção é obrigatória") }),
    option3: z.string().optional(),
    option4: z.string().optional(),
    option5: z.string().optional(),
  });
export interface CreateEvaluationFieldYesOrNo extends z.infer<ReturnType<typeof CreateEvaluationFieldYesOrNoSchema>> {}
export const UpdateEvaluationFieldYesOrNoSchema = () =>
  z.object({
    label: z.string().min(1, msg("A legenda é obrigatória")).optional(),
    option1: z.string().min(1, msg("A opção é obrigatória")).optional(),
    option2: z.string().min(1, msg("A opção é obrigatória")).optional(),
    option3: z.string().optional(),
    option4: z.string().optional(),
    option5: z.string().optional(),
  });
export interface UpdateEvaluationFieldYesOrNo extends z.infer<ReturnType<typeof UpdateEvaluationFieldYesOrNoSchema>> {}

/* *********************** Evaluation Field ********************** */

export const EvaluationFieldSchema = z.union([
  EvaluationFieldResultIntegerSchema,
  EvaluationFieldResultPercentageSchema,
  EvaluationFieldYesOrNoSchema,
  EvaluationFieldAttachmentSchema,
  EvaluationFieldNumericBoxSchema,
  EvaluationFieldScaleSchema,
  EvaluationFieldTextBoxSchema,
  EvaluationFieldUrlSchema,
]);
export type EvaluationField = z.infer<typeof EvaluationFieldSchema>;

/* *********************** Evaluations Sections ********************** */
export const EvaluationSectionRecordSchema = record({
  id: z.string(),
  evaluation: z.string(),
  label: z.string(),
  position: z.number(),
});
export interface EvaluationSectionRecord extends z.infer<typeof EvaluationSectionRecordSchema> {}

export const EvaluationSectionSummarySchema = EvaluationSectionRecordSchema.omit({ expand: true });
export interface EvaluationSectionSummary extends z.infer<typeof EvaluationSectionSummarySchema> {}
export function createEvaluationSectionSummary(data: unknown) {
  return unsafeParse(EvaluationSectionSummarySchema, data);
}

export const EvaluationSectionSchema = EvaluationSectionSummarySchema.extend({
  fields: z.array(EvaluationFieldSchema).default([]),
});
export interface EvaluationSection extends z.infer<typeof EvaluationSectionSchema> {}
export function createEvaluationSection(data: unknown) {
  return unsafeParse(EvaluationSectionSchema, data);
}

export const CreateEvaluationSectionSchema = () =>
  z.object({
    evaluation: z.string(),
    label: z
      .string({ required_error: msg("A legenda é obrigatória") })
      .min(2, msg("A legenda tem que ter no mínimo 2 caracteres"))
      .max(32, msg("A legenda tem que ter no máximo 32 caracteres")),
    position: z.number(),
  });
export interface CreateEvaluationSection extends z.infer<ReturnType<typeof CreateEvaluationSectionSchema>> {}

export const UpdateEvaluationSectionSchema = () =>
  z.object({
    label: z
      .string({ required_error: msg("A legenda é obrigatória") })
      .min(2, msg("A legenda tem que ter no mínimo 2 caracteres"))
      .max(32, msg("A legenda tem que ter no máximo 32 caracteres"))
      .optional(),
    position: z.number().optional(),
  });
export interface UpdateEvaluationSection extends z.infer<ReturnType<typeof UpdateEvaluationSectionSchema>> {}

/* *********************** Evaluations Entity ********************** */
export const EvaluationRecordSchema = record({
  id: z.string(),
  report: z.string(),
  name: z.string(),
});
export interface EvaluationRecord extends z.infer<typeof EvaluationRecordSchema> {}

export const EvaluationSummarySchema = EvaluationRecordSchema.extend({
  categories: z.array(ReportCategorySchema).default([]),
}).omit({ expand: true });
export interface EvaluationSummary extends z.infer<typeof EvaluationSummarySchema> {}
export function createEvaluationSummary(data: unknown) {
  return unsafeParse(EvaluationSummarySchema, data);
}

export const EvaluationSchema = EvaluationSummarySchema.extend({
  sections: z.array(EvaluationSectionSchema).default([]),
  categories: z.array(ReportCategorySchema).default([]),
});
export interface Evaluation extends z.infer<typeof EvaluationSchema> {}
export function createEvaluation(data: unknown) {
  return unsafeParse(EvaluationSchema, data);
}

export const CreateEvaluationSchema = z.object({
  report: z.string(),
  name: z.string(),
  categories: z.array(z.string()).optional(),
});
export interface CreateEvaluation extends z.infer<typeof CreateEvaluationSchema> {}

export const UpdateEvaluationSchema = () =>
  z.object({
    name: z
      .string({ required_error: msg("O nome é obrigatório") })
      .min(3, msg("O nome deve ter no mínimo 3 caracteres"))
      .max(64, msg("O nome deve ter no máximo 64 caracteres")),
    categories: z.array(z.string()).optional(),
    "categories-": z.array(z.string()).optional(),
  });
export interface UpdateEvaluation extends z.infer<ReturnType<typeof UpdateEvaluationSchema>> {}

export const UpdateReportSchema = () =>
  z.object({
    name: z
      .string({ required_error: msg("O nome é obrigatório") })
      .min(3, msg("O nome deve ter no mínimo 3 caracteres"))
      .max(50, msg("O nome deve ter no máximo 50 caracteres")),
    group: z.string().optional(),
  });
export interface UpdateReport extends z.infer<ReturnType<typeof UpdateReportSchema>> {}

/********************************************************************
 *************************** REPORTS ********************************
 ********************************************************************/

/* ********************** Group Shared **************+************ */
export const ReportSharedGroupRecordSchema = record({
  report: z.string(),
  group: z.string(),
});
export interface ReportSharedGroupRecord extends z.infer<typeof ReportSharedGroupRecordSchema> {}

export const ReportSharedGroupSchema = ReportSharedGroupRecordSchema.extend({
  group: GroupRecordSchema,
}).omit({ expand: true });
export interface ReportSharedGroup extends z.infer<typeof ReportSharedGroupSchema> {}
export function createReportSharedGroup(data: unknown) {
  return unsafeParse(ReportSharedGroupSchema, data);
}

/* *********************** Personal Shared ********************** */
export const ReportSharedPersonalRecordSchema = record({
  report: z.string(),
  user: z.string(),
  access: z.string(),
});
export interface ReportSharedPersonalRecord extends z.infer<typeof ReportSharedPersonalRecordSchema> {}

export const ReportSharedPersonalSchema = ReportSharedPersonalRecordSchema.extend({
  user: UserSchema,
  access: AccessSchema,
}).omit({ expand: true });
export interface ReportSharedPersonal extends z.infer<typeof ReportSharedPersonalSchema> {}
export function createReportSharedPersonal(data: unknown) {
  return unsafeParse(ReportSharedPersonalSchema, data);
}

/* *********************** Reports Entity ********************** */
export const ReportRecordSchema = record({
  id: z.string(),
  name: z.string(),
  owner: z.string(),
});
export interface ReportRecord extends z.infer<typeof ReportRecordSchema> {}

export const ReportSummarySchema = ReportRecordSchema.extend({
  owner: UserSchema,
  sharedGroup: z.array(ReportSharedGroupSchema).default([]).optional(),
  sharedPersonal: z.array(ReportSharedPersonalSchema).default([]).optional(),
  totalEvaluations: z.number().optional().default(0),
  totalScales: z.number().optional().default(0),
}).omit({ expand: true });
export interface ReportSummary extends z.infer<typeof ReportSummarySchema> {}
export function createSummaryReport(data: unknown) {
  return unsafeParse(ReportSummarySchema, data);
}

const UserAccessSchema = z.object({
  userId: z.string(),
  accessId: z.string(),
});

export const CreateReportSchema = () =>
  z.object({
    name: z
      .string({ required_error: msg("O nome é obrigatório") })
      .min(3, msg("O nome deve ter no mínimo 3 caracteres"))
      .max(50, msg("O nome deve ter no máximo 50 caracteres")),
    owner: z.string(),
    users: z.array(UserAccessSchema).optional(),
    group: z.string().optional(),
  });
export interface CreateReport extends z.infer<ReturnType<typeof CreateReportSchema>> {}

/********************************************************************
 ******************** PATIENT EVALUATION ****************************
 ********************************************************************/

export const PatientEvaluationRecordSchema = record({
  id: z.string(),
  patient: z.string(),
  session: z.string(),
  evaluation: z.string(),
});
export interface PatientEvaluationRecord extends z.infer<typeof PatientEvaluationRecordSchema> {}
export const PatientEvaluationSchema = PatientEvaluationRecordSchema.extend({
  evaluation: EvaluationSummarySchema,
}).omit({ expand: true });
export interface PatientEvaluation extends z.infer<typeof PatientEvaluationSchema> {}
export function createPatientEvaluation(data: unknown) {
  return unsafeParse(PatientEvaluationSchema, data);
}

export const CreatePatientEvaluationSchema = z.object({
  patient: z.string(),
  session: z.string(),
  evaluation: z.string(),
});
export interface CreatePatientEvaluation extends z.infer<typeof CreatePatientEvaluationSchema> {}

/********************************************************************
 ************************* FIELD VALUES *****************************
 ********************************************************************/

export const ValueAttachmentRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  content: z.array(z.string()),
});
export interface ValueAttachmentRecord extends z.infer<typeof ValueAttachmentRecordSchema> {}
export const ValueAttachmentSchema = ValueAttachmentRecordSchema.omit({ expand: true });
export interface ValueAttachment extends z.infer<typeof ValueAttachmentSchema> {}
export function createValueAttachment(data: Record<string, unknown>) {
  data["content"] = (data["content"] as string[]).map((filename) => {
    return pb.getFileUrl(data, filename);
  });
  return unsafeParse(ValueAttachmentSchema, data);
}
export const CreateValueAttachmentSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  content: file(
    {
      size: 5 * 1024 * 1024,
      allowedFileTypes: [
        "image/jpeg",
        "image/jpg",
        "image/avif",
        "image/png",
        "image/webp",
        "image/heic",
        "image/heif",
        "image/gif",
        "video/mp4",
        "video/webm",
      ],
    },
    "O conteúdo deve ser menor que 5MB e ter o formato jpeg, avif, png, webp, heic, heif, gif, mp4 ou webm",
  ),
});
export interface CreateValueAttachment extends z.infer<typeof CreateValueAttachmentSchema> {}
export const UpdateValueAttachmentSchema = z.object({
  content: file(
    {
      size: 5 * 1024 * 1024,
      allowedFileTypes: [
        "image/jpeg",
        "image/jpg",
        "image/avif",
        "image/png",
        "image/webp",
        "image/heic",
        "image/heif",
        "image/gif",
        "video/mp4",
        "video/webm",
      ],
    },
    "O conteúdo deve ser menor que 5MB e ter o formato jpeg, avif, png, webp, heic, heif, gif, mp4 ou webm",
  ).optional(),
  "content-": z.string().optional(),
});
export interface UpdateValueAttachment extends z.infer<typeof UpdateValueAttachmentSchema> {}

export const ValueScaleRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.string(),
});
export interface ValueScaleRecord extends z.infer<typeof ValueScaleRecordSchema> {}
export const ValueScaleSchema = ValueScaleRecordSchema.extend({
  field: ScaleFieldSummarySchema,
  value: ScaleOptionSchema,
}).omit({ expand: true });
export interface ValueScale extends z.infer<typeof ValueScaleSchema> {}
export function createValueScale(data: unknown) {
  return unsafeParse(ValueScaleSchema, data);
}
export const CreateValueScaleSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.string(),
});
export interface CreateValueScale extends z.infer<typeof CreateValueScaleSchema> {}
export const UpdateValueScaleSchema = z.object({
  value: z.string().optional(),
});
export interface UpdateValueScale extends z.infer<typeof UpdateValueScaleSchema> {}

export const ValueNumericBoxRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.number(),
});
export interface ValueNumericBoxRecord extends z.infer<typeof ValueNumericBoxRecordSchema> {}
export const ValueNumericBoxSchema = ValueNumericBoxRecordSchema.omit({ expand: true });
export interface ValueNumericBox extends z.infer<typeof ValueNumericBoxSchema> {}
export function createValueNumericBox(data: unknown) {
  return unsafeParse(ValueNumericBoxSchema, data);
}
export const CreateValueNumericBoxSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.number(),
});
export interface CreateValueNumericBox extends z.infer<typeof CreateValueNumericBoxSchema> {}
export const UpdateValueNumericBoxSchema = z.object({
  value: z.number().optional(),
});
export interface UpdateValueNumericBox extends z.infer<typeof UpdateValueNumericBoxSchema> {}

export const ValueResultIntegerRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.number(),
});
export interface ValueResultIntegerRecord extends z.infer<typeof ValueResultIntegerRecordSchema> {}
export const ValueResultIntegerSchema = ValueResultIntegerRecordSchema.omit({ expand: true });
export interface ValueResultInteger extends z.infer<typeof ValueResultIntegerSchema> {}
export function createValueResultInteger(data: unknown) {
  return unsafeParse(ValueResultIntegerSchema, data);
}
export const CreateValueResultIntegerSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.number(),
});
export interface CreateValueResultInteger extends z.infer<typeof CreateValueResultIntegerSchema> {}
export const UpdateValueResultIntegerSchema = z.object({
  value: z.number().optional(),
});
export interface UpdateValueResultInteger extends z.infer<typeof UpdateValueResultIntegerSchema> {}

export const ValueResultPercentageRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.number(),
});
export interface ValueResultPercentageRecord extends z.infer<typeof ValueResultPercentageRecordSchema> {}
export const ValueResultPercentageSchema = ValueResultPercentageRecordSchema.omit({ expand: true });
export interface ValueResultPercentage extends z.infer<typeof ValueResultPercentageSchema> {}
export function createValueResultPercentage(data: unknown) {
  return unsafeParse(ValueResultPercentageSchema, data);
}
export const CreateValueResultPercentageSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.number(),
});
export interface CreateValueResultPercentage extends z.infer<typeof CreateValueResultPercentageSchema> {}
export const UpdateValueResultPercentageSchema = z.object({
  value: z.number().optional(),
});
export interface UpdateValueResultPercentage extends z.infer<typeof UpdateValueResultPercentageSchema> {}

export const ValueTextBoxRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.string(),
});
export interface ValueTextBoxRecord extends z.infer<typeof ValueTextBoxRecordSchema> {}
export const ValueTextBoxSchema = ValueTextBoxRecordSchema.omit({ expand: true });
export interface ValueTextBox extends z.infer<typeof ValueTextBoxSchema> {}
export function createValueTextBox(data: unknown) {
  return unsafeParse(ValueTextBoxSchema, data);
}
export const CreateValueTextBoxSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.string(),
});
export interface CreateValueTextBox extends z.infer<typeof CreateValueTextBoxSchema> {}
export const UpdateValueTextBoxSchema = z.object({
  value: z.string().optional(),
});
export interface UpdateValueTextBox extends z.infer<typeof UpdateValueTextBoxSchema> {}

export const ValueUrlRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.string(),
});
export interface ValueUrlRecord extends z.infer<typeof ValueUrlRecordSchema> {}
export const ValueUrlSchema = ValueUrlRecordSchema.omit({ expand: true });
export interface ValueUrl extends z.infer<typeof ValueUrlSchema> {}
export function createValueUrl(data: unknown) {
  return unsafeParse(ValueUrlSchema, data);
}
export const CreateValueUrlSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  value: z.string(),
});
export interface CreateValueUrl extends z.infer<typeof CreateValueUrlSchema> {}
export const UpdateValueUrlSchema = z.object({
  value: z.string().optional(),
});
export interface UpdateValueUrl extends z.infer<typeof UpdateValueUrlSchema> {}

export const ValueYesOrNoRecordSchema = record({
  id: z.string(),
  patient_evaluation: z.string(),
  field: z.string(),
  option1: z.boolean(),
  option2: z.boolean(),
  option3: z.boolean(),
  option4: z.boolean(),
  option5: z.boolean(),
});
export interface ValueYesOrNoRecord extends z.infer<typeof ValueYesOrNoRecordSchema> {}
export const ValueYesOrNoSchema = ValueYesOrNoRecordSchema.omit({ expand: true });
export interface ValueYesOrNo extends z.infer<typeof ValueYesOrNoSchema> {}
export function createValueYesOrNo(data: unknown) {
  return unsafeParse(ValueYesOrNoSchema, data);
}
export const CreateValueYesOrNoSchema = z.object({
  patient_evaluation: z.string(),
  field: z.string(),
  option1: z.boolean(),
  option2: z.boolean(),
  option3: z.boolean(),
  option4: z.boolean(),
  option5: z.boolean(),
});
export interface CreateValueYesOrNo extends z.infer<typeof CreateValueYesOrNoSchema> {}
export const UpdateValueYesOrNoSchema = z.object({
  option1: z.boolean().optional(),
  option2: z.boolean().optional(),
  option3: z.boolean().optional(),
  option4: z.boolean().optional(),
  option5: z.boolean().optional(),
});
export interface UpdateValueYesOrNo extends z.infer<typeof UpdateValueYesOrNoSchema> {}

export const FieldValueAttachmentSchema = EvaluationFieldAttachmentSchema.extend({
  value: ValueAttachmentSchema.optional(),
});
export interface FieldValueAttachment extends z.infer<typeof FieldValueAttachmentSchema> {}
export function createFieldValueAttachment(data: unknown) {
  return unsafeParse(FieldValueAttachmentSchema, data);
}

export const FieldValueScaleSchema = EvaluationFieldScaleSchema.extend({
  value: z.array(ValueScaleSchema).optional(),
});
export interface FieldValueScale extends z.infer<typeof FieldValueScaleSchema> {}
export function createFieldValueScale(data: unknown) {
  return unsafeParse(FieldValueScaleSchema, data);
}

export const FieldValueNumericBoxSchema = EvaluationFieldNumericBoxSchema.extend({
  value: ValueNumericBoxSchema.optional(),
});
export interface FieldValueNumericBox extends z.infer<typeof FieldValueNumericBoxSchema> {}
export function createFieldValueNumericBox(data: unknown) {
  return unsafeParse(FieldValueNumericBoxSchema, data);
}

export const FieldValueResultIntegerSchema = EvaluationFieldResultIntegerSchema.extend({
  value: ValueResultIntegerSchema.optional(),
});
export interface FieldValueResultInteger extends z.infer<typeof FieldValueResultIntegerSchema> {}
export function createFieldValueResultInteger(data: unknown) {
  return unsafeParse(FieldValueResultIntegerSchema, data);
}

export const FieldValueResultPercentageSchema = EvaluationFieldResultPercentageSchema.extend({
  value: ValueResultPercentageSchema.optional(),
});
export interface FieldValueResultPercentage extends z.infer<typeof FieldValueResultPercentageSchema> {}
export function createFieldValueResultPercentage(data: unknown) {
  return unsafeParse(FieldValueResultPercentageSchema, data);
}

export const FieldValueTextBoxSchema = EvaluationFieldTextBoxSchema.extend({
  value: ValueTextBoxSchema.optional(),
});
export interface FieldValueTextBox extends z.infer<typeof FieldValueTextBoxSchema> {}
export function createFieldValueTextBox(data: unknown) {
  return unsafeParse(FieldValueTextBoxSchema, data);
}

export const FieldValueUrlSchema = EvaluationFieldUrlSchema.extend({
  value: ValueUrlSchema.optional(),
});
export interface FieldValueUrl extends z.infer<typeof FieldValueUrlSchema> {}
export function createFieldValueUrl(data: unknown) {
  return unsafeParse(FieldValueUrlSchema, data);
}

export const FieldValueYesOrNoSchema = EvaluationFieldYesOrNoSchema.extend({
  value: ValueYesOrNoSchema.optional(),
});
export interface FieldValueYesOrNo extends z.infer<typeof FieldValueYesOrNoSchema> {}
export function createFieldValueYesOrNo(data: unknown) {
  return unsafeParse(FieldValueYesOrNoSchema, data);
}

export const FieldValueSchema = z.union([
  FieldValueAttachmentSchema,
  FieldValueScaleSchema,
  FieldValueNumericBoxSchema,
  FieldValueResultIntegerSchema,
  FieldValueResultPercentageSchema,
  FieldValueTextBoxSchema,
  FieldValueUrlSchema,
  FieldValueYesOrNoSchema,
]);
export type FieldValue = z.infer<typeof FieldValueSchema>;

export const EvaluationSectionWithValuesSchema = EvaluationSectionSummarySchema.extend({
  fields: z.array(FieldValueSchema).default([]),
});
export interface EvaluationSectionWithValues extends z.infer<typeof EvaluationSectionWithValuesSchema> {}
export function createEvaluationSectionWithValues(data: unknown) {
  return unsafeParse(EvaluationSectionWithValuesSchema, data);
}

export const EvaluationWithValuesSchema = EvaluationSummarySchema.extend({
  sections: z
    .array(
      EvaluationSectionWithValuesSchema.extend({
        fields: z.array(FieldValueSchema).default([]),
      }),
    )
    .default([]),
});
export interface EvaluationWithValues extends z.infer<typeof EvaluationWithValuesSchema> {}
export function createEvaluationWithValues(data: unknown) {
  return unsafeParse(EvaluationWithValuesSchema, data);
}
