import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import { alertController, toastController } from "@ionic/core";
import type { ModalSheetNav } from "../modals/modal-sheet-nav";
import { Task, TaskStatus } from "@lit/task";
import { when } from "lit/directives/when.js";
import { ObservablePatient, patientsStore } from "../stores/patients.store";
import { repeat } from "lit/directives/repeat.js";
import type { ModalNav } from "../modals/modal-nav";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { groupStore } from "../stores/groups.store";
import { authContext, type AuthContext } from "../context/auth.context";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

@customElement("page-patient")
@localized()
export class PagePatient extends Page {
  @state() private _patientId: string = "";
  @state() private _groupId: string = "";

  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;

  connectedCallback() {
    super.connectedCallback();
    this._patientId = this.router.getParam("patientId");
    this._groupId = this.router.getParam("groupId");
    this.#group.run([this._groupId, true]);
    this.#patient.run([this._patientId, true]);
  }

  #patient = new Task(this, {
    task: async ([id, cache]: [string, boolean]) => {
      return patientsStore.loadPatient(id, { cache });
    },
  });

  #group = new Task(this, {
    task: async ([id, cache]: [string, boolean]) => {
      return groupStore.loadGroup(id, { cache });
    },
  });

  #archivePatient = new Task(this, {
    task: async ([patient]: [ObservablePatient]) => {
      await patientsStore.archivePatient(patient);
    },
    onError: async (error) => {
      console.error(error);
      if (error instanceof ApiError) {
        return alertController
          .create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          })
          .then((alert) => alert.present());
      } else {
        return alertController
          .create({
            header: msg("Erro"),
            message: msg("Ocorreu um erro ao arquivar o utente, por favor tente novamente"),
            buttons: ["OK"],
          })
          .then((alert) => alert.present());
      }
    },
  });

  #modalDupPatientRef: Ref<ModalSheetNav> = createRef();
  #modalEditPatientRef: Ref<ModalNav> = createRef();
  #modalNewDiagnosticRef: Ref<ModalNav> = createRef();

  #ionRefresher: Ref<IonRefresher> = createRef();

  async presentSuccessToast() {
    const toast = await toastController.create({
      message: msg("Utente duplicado com sucesso"),
      duration: 3000,
      position: "bottom",
      color: "primary",
      icon: "/assets/icons/info.svg",
      buttons: [
        {
          text: msg("Desfazer"),
          role: "cancel",
          handler: () => {},
        },
      ],
    });

    await toast.present();
  }

  calculateAge(birthday: string | undefined) {
    if (!birthday) return;
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  formatDiagnosticDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Pad the month and day with a leading zero if necessary
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  async archivePatient() {
    const patient = this.#patient.value;
    if (patient) {
      await this.#archivePatient.run([patient]);
      if (this.#archivePatient.status === TaskStatus.ERROR) return;
      const basename = this.router.pathname.split("/").slice(0, -2).join("/");
      this.router.push(`${basename}/patient-archived/${this._patientId}`, "root");
    }
  }

  async createPublicLink(patient: ObservablePatient) {
    const url = window.location.origin + `/public/patient/${patient.id}`;
    await navigator.clipboard.writeText(url);
    const toast = await toastController.create({
      message: msg("Link copiado para a área de transferência"),
      duration: 3000,
      position: "bottom",
      color: "primary",
      icon: "/assets/icons/info.svg",
    });
    await toast.present();
  }

  canEdit() {
    const group = groupStore.find(this._groupId);
    if (!group) return false;
    return group.canEdit(this.auth.user);
  }

  isOwner() {
    const group = groupStore.find(this._groupId);
    if (!group) return false;
    return group.isOwner(this.auth.user);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <div class="flex justify-between items-center">
            <ion-router-link href=${this.router.backUrl} router-direction="back">
              <ion-button fill="clear" class="font-semibold no-p no-inline-p no-m-inline no-m">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </ion-router-link>

            ${when(
              this.canEdit() && this.#patient.status !== TaskStatus.ERROR,
              () => html`
                <div class="flex items-center space-x-1.5">
                  ${this.isOwner()
                    ? html`
                        <ion-button
                          style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                          color="secondary"
                          shape="round"
                          size="xsmall"
                          @click=${() =>
                            this.#modalDupPatientRef.value?.open({
                              patient: this.#patient.value,
                            })}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-4 mr-1"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round">
                            <path d="m21 16-4 4-4-4" />
                            <path d="M17 20V4" />
                            <path d="m3 8 4-4 4 4" />
                            <path d="M7 4v16" />
                          </svg>
                          ${msg("Mover")}
                        </ion-button>
                      `
                    : nothing}

                  <ion-button
                    style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                    color="secondary"
                    shape="round"
                    size="xsmall"
                    @click=${() =>
                      this.#modalEditPatientRef.value?.open({
                        patient: this.#patient.value,
                        groupId: this._groupId,
                      })}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="mr-1">
                      <path
                        d="M8.00033 5.33341L2.66699 10.6667V13.3334L5.33366 13.3334L10.667 8.00007M8.00033 5.33341L9.91274 3.42098L9.91389 3.41984C10.1772 3.15659 10.309 3.02473 10.461 2.97534C10.5949 2.93183 10.7392 2.93183 10.873 2.97534C11.0249 3.02469 11.1567 3.1564 11.4195 3.41929L12.5794 4.57915C12.8434 4.84316 12.9755 4.97523 13.0249 5.12745C13.0684 5.26134 13.0684 5.40557 13.0249 5.53947C12.9755 5.69158 12.8436 5.82344 12.58 6.08708L12.5794 6.08764L10.667 8.00007M8.00033 5.33341L10.667 8.00007"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    ${msg("Editar")}
                  </ion-button>
                </div>
              `,
            )}
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4 no-px" fullscreen>
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            await this.#patient.run([this._patientId, false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>
        <!-- HEADER -->
        <div class="space-y-4">
          ${this.#patient.render({
            pending: () => html`
              <div class="flex items-center space-x-3 px-4">
                <ion-skeleton-text animated style="width: 72px; height: 72px;"></ion-skeleton-text>
                <div>
                  <ion-skeleton-text animated style="height: 28px; width: 120px"></ion-skeleton-text>
                  <div class="flex space-x-1 items-center">
                    <ion-skeleton-text animated style="height: 26px; width: 200px"></ion-skeleton-text>
                  </div>
                </div>
              </div>
              <div class="space-y-2">
                <div class="mt-1 mb-3 h-1 w-full bg-accent-7"></div>
                <div class="px-4 space-y-2">
                  <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
                  <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
                  <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
                </div>
                <div>
                  <div class="mt-3 h-1 w-full bg-accent-7"></div>
                </div>
              </div>

              <div class="space-y-3 px-4">
                <ion-skeleton-text animated style="height: 24px; width: 150px;"></ion-skeleton-text>
                <ion-skeleton-text animated class="h-40"></ion-skeleton-text>
                <ion-skeleton-text animated class="h-10"></ion-skeleton-text>
              </div>
              <div class="mt-3 h-1 w-full bg-accent-7"></div>

              <div class="space-y-3 px-4">
                <ion-skeleton-text animated style="height: 24px; width: 150px;"></ion-skeleton-text>
                <ion-skeleton-text animated class="h-12"></ion-skeleton-text>
                <ion-skeleton-text animated class="h-10"></ion-skeleton-text>
              </div>
            `,
            error: (err) => {
              console.log(err);
              if (err instanceof ApiError) {
                return html`<div
                  class="mx-4 mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ee0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                    ${err.message}
                  </span>
                </div>`;
              } else {
                return html`<div
                  class="mx-4 mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ee0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                    ${msg("Erro ao pesquisar")}
                  </span>
                </div>`;
              }
            },
            complete: (patient) => html`
              <div class="flex items-start gap-x-3 px-4">
                <xt-avatar
                  style="--size: 72px; --border-radius: 99px;"
                  src="${patient.avatar}"
                  name="${patient.name}"></xt-avatar>
                <div class="self-center">
                  <p class="text-xl font-display font-semibold">${patient.name}</p>
                  <div class="flex flex-wrap gap-x-1 items-center">
                    <span class="font-medium">${msg(str`${this.calculateAge(patient.birthdate)} anos`)}</span>
                    ${when(
                      patient.categories && patient.categories.length > 0,
                      () => html`
                        <span class="text-accent-6">•</span>
                        <div class="flex flex-wrap items-center gap-x-1.5 gap-y-1">
                          ${patient.categories?.map(
                            (category) => html`
                              <ion-chip class="px-2 w-fit" style="--background: ${category.color};"
                                >${category?.category}</ion-chip
                              >
                            `,
                          )}
                        </div>
                      `,
                    )}
                  </div>
                </div>
              </div>

              <div class="space-y-2">
                <div class="mt-1 h-1 w-full bg-accent-7"></div>
                <ion-list class="px-4">
                  ${when(
                    patient!.email?.length > 0,
                    () => html` <ion-item class="no-p no-inner-p text-sm">
                      <div class="py-3.5 flex items-center justify-between w-full gap-x-3">
                        <div class="flex items-center space-x-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <path
                              d="M3.33333 5.00001L8.42303 8.84356L8.42473 8.84497C8.98987 9.25941 9.27261 9.46675 9.5823 9.54685C9.85602 9.61765 10.1438 9.61765 10.4175 9.54685C10.7274 9.46668 11.011 9.25873 11.5771 8.84356C11.5771 8.84356 14.8417 6.33829 16.6667 5.00001M2.5 13.1668V6.8335C2.5 5.90008 2.5 5.43302 2.68166 5.0765C2.84144 4.7629 3.09623 4.50812 3.40983 4.34833C3.76635 4.16667 4.23341 4.16667 5.16683 4.16667H14.8335C15.7669 4.16667 16.233 4.16667 16.5895 4.34833C16.9031 4.50812 17.1587 4.7629 17.3185 5.0765C17.5 5.43267 17.5 5.89917 17.5 6.83076V13.1697C17.5 14.1012 17.5 14.5671 17.3185 14.9232C17.1587 15.2368 16.9031 15.4921 16.5895 15.6519C16.2333 15.8333 15.7675 15.8333 14.8359 15.8333H5.16409C4.23249 15.8333 3.766 15.8333 3.40983 15.6519C3.09623 15.4921 2.84144 15.2368 2.68166 14.9232C2.5 14.5667 2.5 14.1003 2.5 13.1668Z"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <ion-label class="font-semibold">Email</ion-label>
                        </div>
                        <ion-note class="text-right">${patient.email}</ion-note>
                      </div>
                    </ion-item>`,
                  )}
                  <ion-item class="no-p no-inner-p text-sm">
                    <div class="py-3.5 flex items-center justify-between w-full gap-x-3">
                      <div class="flex items-center space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M7.91872 3.54768C7.66561 2.91492 7.05276 2.5 6.37126 2.5H4.07895C3.20692 2.5 2.5 3.20675 2.5 4.07878C2.5 11.491 8.50898 17.5 15.9212 17.5C16.7933 17.5 17.5 16.793 17.5 15.921L17.5004 13.6283C17.5004 12.9468 17.0856 12.334 16.4528 12.0809L14.2558 11.2024C13.6874 10.9751 13.0402 11.0774 12.57 11.4693L12.0029 11.9422C11.3407 12.4941 10.3664 12.4502 9.75683 11.8407L8.16018 10.2425C7.55065 9.63302 7.50561 8.65945 8.05745 7.99724L8.53027 7.43025C8.92218 6.95996 9.02541 6.31263 8.79805 5.74424L7.91872 3.54768Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <ion-label class="font-semibold">${msg("Telemóvel")}</ion-label>
                      </div>
                      <ion-note class="text-right">${patient.phone}</ion-note>
                    </div>
                  </ion-item>
                  ${when(
                    patient!.address?.length > 0,
                    () => html`
                      <ion-item class="no-p no-inner-p text-sm">
                        <div class="py-3.5 flex items-start justify-between w-full gap-x-3">
                          <div class="flex items-center space-x-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none">
                              <path
                                d="M1.66666 16.6667H3.33333M3.33333 16.6667H8.33333M3.33333 16.6667V9.54352C3.33333 9.09824 3.33333 8.87548 3.38747 8.66828C3.43545 8.48466 3.51482 8.31089 3.62142 8.15387C3.7417 7.97668 3.90886 7.82974 4.24398 7.53652L8.2453 4.03537C8.86654 3.49178 9.17717 3.21997 9.52701 3.11652C9.83551 3.02529 10.1643 3.02529 10.4728 3.11652C10.8229 3.22005 11.134 3.49212 11.7562 4.03652L15.7562 7.53652C16.0913 7.82974 16.2585 7.97668 16.3788 8.15387C16.4854 8.31089 16.5642 8.48466 16.6121 8.66828C16.6663 8.87548 16.6667 9.09824 16.6667 9.54352V16.6667M8.33333 16.6667H11.6667M8.33333 16.6667V13.3334C8.33333 12.4129 9.07952 11.6667 10 11.6667C10.9205 11.6667 11.6667 12.4129 11.6667 13.3334V16.6667M11.6667 16.6667H16.6667M16.6667 16.6667H18.3333"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                            <ion-label class="font-semibold">${msg("Residência")}</ion-label>
                          </div>
                          <ion-note class="text-right">${patient.address}</ion-note>
                        </div>
                      </ion-item>
                    `,
                  )}
                  ${when(
                    patient.clinicInfo,
                    () => html`
                      <ion-item lines="none" class="no-p no-inner-p text-sm">
                        <div class="pt-3.5 w-full">
                          <div class="flex items-center space-x-1 mb-2.5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none">
                              <path
                                d="M10 9.16667V13.3333M10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5ZM10.0415 6.66667V6.75L9.9585 6.75016V6.66667H10.0415Z"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                            <ion-label class="font-semibold">${msg("Informação Clínica")}</ion-label>
                          </div>
                          <ion-note>${patient.clinicInfo}</ion-note>
                        </div>
                      </ion-item>
                    `,
                  )}
                </ion-list>
                <div>
                  <div class="mt-3 h-1 w-full bg-accent-7"></div>
                </div>
              </div>

              <!-- DIAGNÓSTICOS -->

              <div class="space-y-3 px-4">
                <div class="space-y-1.5">
                  <p class="font-display font-semibold">${msg("Diagnósticos")}</p>
                  <div class="space-y-2.5 flex flex-col">
                    ${when(
                      patient.diagnostics.length === 0,
                      () => html`
                        <div
                          class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none">
                            <path
                              d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                              stroke="#111111"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                            ${msg("Sem diagnósticos")}
                          </span>
                        </div>
                      `,
                    )}
                    ${repeat(
                      patient.diagnostics ?? [],
                      (diagnostic) => diagnostic.id,
                      (diagnostic) => {
                        return html`
                          <ion-router-link
                            href="${this.router.pathname}/diagnostics/${diagnostic.id}"
                            router-direction="forward">
                            <div
                              class="
                            w-full p-3.5 bg-accent-8 border border-solid border-accent-7 rounded-md h-40 col justify-between
                            ${diagnostic.isCompleted && "opacity-70"}
                            ">
                              <div class="col space-y-1 items-start">
                                <div class="flex justify-between items-start w-full gap-x-2">
                                  <p class="font-semibold">${diagnostic.name}</p>
                                  ${when(
                                    diagnostic.isCompleted,
                                    () => html`
                                      <ion-badge
                                        style="--background: #F7B955;"
                                        class="text-xs font-medium text-foreground px-2 py-0.5 shrink-0 mt-1"
                                        >${msg("Tratamento Concluído")}</ion-badge
                                      >
                                    `,
                                    () => html`
                                      <ion-badge
                                        style="--background: #79FFE1;"
                                        class="text-xs font-medium text-black px-2 py-0.5 shrink-0 mt-1"
                                        >${msg("Em tratamento")}</ion-badge
                                      >
                                    `,
                                  )}
                                </div>
                                <span class="text-sm text-accent-4 line-clamp-2">${diagnostic.description}</span>
                              </div>
                              <div class="flex items-baseline justify-between">
                                <span class="text-sm font-semibold font-display"
                                  >${this.formatDiagnosticDate(diagnostic.created)}</span
                                >
                                <div class="flex items-baseline -space-x-4">
                                  ${diagnostic.professionals
                                    ?.slice(0, 2)
                                    .map(
                                      (item) => html`
                                        <xt-avatar
                                          style="--size: 40px; --border-radius: 99px; aspect-ratio: 1; object-fit: cover;"
                                          src="${item.avatar}"
                                          name="${item.name}"></xt-avatar>
                                      `,
                                    )}
                                </div>
                              </div>
                            </div>
                          </ion-router-link>
                        `;
                      },
                    )}
                  </div>
                </div>

                ${when(
                  this.canEdit(),
                  () => html`
                    <ion-button
                      shape="round"
                      expand="block"
                      size="small"
                      class="h-10 text-sm font-semibold"
                      @click=${() => this.#modalNewDiagnosticRef.value?.open({ patient: patient })}>
                      ${msg("Adicionar Diagnóstico")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="ml-2">
                        <path
                          d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </ion-button>
                  `,
                )}
              </div>

              <!-- DIAGNÓSTICOS -->

              ${when(
                this.canEdit(),
                () => html`
                  <div class="mt-1 h-1 w-full bg-accent-7"></div>

                  <div class="space-y-3 px-4">
                    <div class="space-y-0.5">
                      <p class="font-display font-semibold">${msg("Partilhe com o seu utente")}</p>
                      <span class="text-sm font-medium"
                        >${msg("Partilhe os seus diagnósticos e planos de treinos com o seu utente")}</span
                      >
                    </div>
                    <ion-button shape="round" expand="block" size="small" @click=${() => this.createPublicLink(patient)}
                      >${msg("Obter link público")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        class="ml-2">
                        <path
                          d="M7.64317 12.3574L12.3572 7.64331M5.87571 9.41089L4.6972 10.5894C3.39546 11.8911 3.39508 14.0019 4.69682 15.3036C5.99857 16.6053 8.10975 16.605 9.4115 15.3032L10.5888 14.1248M9.41074 5.87504L10.5893 4.69653C11.891 3.39478 14.0013 3.39502 15.303 4.69676C16.6048 5.99851 16.6047 8.10908 15.303 9.41083L14.1251 10.5893"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </ion-button>
                  </div>

                  <div class="mt-1 h-1 w-full bg-accent-7"></div>

                  <!-- ARQUIVAR -->

                  <div class="space-y-3 pb-7 px-4">
                    <div class="space-y-0.5">
                      <p class="font-display font-semibold">${msg("Arquivar utente")}</p>
                      <span class="text-sm font-medium"
                        >${msg(
                          "O utente deverá ser arquivado quando já não existe nenhuma atividade relativamente ao grupo a que foi adicionado",
                        )}</span
                      >
                    </div>
                    <ion-button
                      @click=${() => this.archivePatient()}
                      color="danger"
                      shape="round"
                      expand="block"
                      size="small"
                      >${msg("Arquivar Utente")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        class="ml-2">
                        <path
                          d="M12.5 15.8334C12.5 13.9924 10.2614 12.5 7.5 12.5C4.73858 12.5 2.5 13.9924 2.5 15.8334M12.5 10.8334H17.5M7.5 10C5.65905 10 4.16667 8.50766 4.16667 6.66671C4.16667 4.82576 5.65905 3.33337 7.5 3.33337C9.34095 3.33337 10.8333 4.82576 10.8333 6.66671C10.8333 8.50766 9.34095 10 7.5 10Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </ion-button>
                  </div>
                `,
              )}
            `,
          })}
        </div>
      </ion-content>

      <modal-sheet-nav
        root="modal-choose-group"
        initial-breakpoint=${0.35}
        .breakpoints=${[0, 0.35, 0.75, 1]}
        ${ref(this.#modalDupPatientRef)}></modal-sheet-nav>

      <modal-nav root="modal-edit-patient" ${ref(this.#modalEditPatientRef)}></modal-nav>
      <modal-nav root="modal-create-diagnostic" ${ref(this.#modalNewDiagnosticRef)}></modal-nav>
    `;
  }
}
